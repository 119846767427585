export default {
  mixRecharge: ["vui lòng điền địa chỉ email"],
  language: "Ngôn ngữ",
  common: ["chăm sóc khách hàng online", "Hủy bỏ treo","Xác nhận","Hủy bỏ"],
  upload: ["đang tải lên...", "quy cách sai", "tải lên thành công", "tải lên thất bại"],
  vanPull: ["Không có số liệu", "Không có dữ liệu"],
  login: {
    text: ["Ngôn ngữ","BitcoinETF","Đăng nhập"],
    label: ["đang đăng nhập...", "Đăng nhập"],
    placeholder: ["Vui lòng điền số điện thoại/email", "Vui lòng điền mật khẩu đăng nhập"],
    default: ["Chưa có tài khoản？", "Đăng ký ngay","Quên mật khẩu?","Quên mật khẩu đăng nhập"],
    codes:["Tài khoản không tồn tại","Mật khẩu sai","Tài khoản bị đóng băng","Thất bại"],
    text2:['Thư', 'Xác nhận', 'Mật khẩu', 'Mời', 'Điện thoại'],
    text1:['Đăng nhập', 'Chào mừng bạn đến với trang web', 'Email hoặc điện thoại', 'Mật khẩu', 'Đăng nhập'],
  },
  register: {
    text: [
      "BitcoinETF",
      "đang gửi mã xác nhận...",
      "Gửi mã",
      "đang đăng ký...",
      "Đăng ký ngay",
    ],
    placeholder: [
      "Vui lòng nhập email",
      "Vui lòng nhập mã xác nhận",
      "Vui lòng nhập mật khẩu",
      "Vui lòng nhập lại mật khẩu",
      "ID giới thiệu",
      "mật khẩu hai lần không giống nhau",
      "vui lòng nhập mã xác nhận",
      "email không có hiệu lực"
    ],
    label: ["đã có tài khoản？{a} {line}", "Quay lại đăng nhập"],
    codes:["Tài khoản đã tồn tại","Chưa có mã xác minh","Mã xác minh không chính xác",
        "2 lần mật khẩu không giống nhau","Chuẩn không tồn tại","Thất bại"]
  },
  resetpwd:["Quên mật khẩu","Vui lòng nhập email","Vui lòng nhập mã xác nhận","Gửi mã","Vui lòng nhập mật khẩu mới","Cài đặt mật khẩu"],
  footer: ["Trang chủ", "Sảnh", "Đơn hàng", "Cá nhân"],
  home: {
      WebMarket:'Thị trường Web',
      special_attention:'Chú ý đặc biệt',
      spot_market:'Thị trường Spot',
    label:["Chào mừng đến với sàn giao dịch XRP"],
    broadcast: "chúc mừng thành viên{member} trở thành{grade}",
    menu: ["Lịch sử ví", "Hướng dẫn", "Mời đăng ký", "Dịch vụ CSKH"],
    noticeTitle: "Thông báo mới nhất",
    msg: "nhiệm vụ chưa mở",
    video: "tạm thời chưa có video hướng dẫn",
  },
  hall:{
    default:[
        "Sảnh giao dịch","Số dư","Tôi muốn mua","Tôi muốn bán","Xác nhận bán ra",
      "danh sách đơn hàng","đơn hàng bán ra","bán cho thương gia","Đang rút đơn","Mua vào","Bán ra"
    ],
    list:[
        'Số lượng','Đơn giá','Số dư đơn','Bán ra USDT','Vui lòng điền số lượng USDT','Số tiền nhận được',"Giới hạn",'mua vào USDT','số tiền tiêu hao'
    ],
    kbip:["bãi bỏ","vượt qua số tiền","nhất định phải trong số tiền quy định"]
  },
  msglist:[
    "danh sách thông tin"
  ],
  sell:{
      placeholder:["Vui lòng nhập giá tiền","Vui lòng nhập số lượng"],
      label:["số dư(USDT)","họ tên","tài khoản ngân hàng","Giá vào (USDT)","Giá ưu đãi nhất","Giá bán ra","Số lượng",
          "Giá ưu đãi","Toàn bộ","Xác nhận bán ra"]
  },
  buy:{
    placeholder:["Vui lòng nhập giá mua vào","Vui lòng nhập số lượng","Hạn mức nhỏ nhất","Hạn mức lớn nhất"],
    label:["số dư(USDT)","họ tên","Số tài khoản","Giá vào(USDT)","Giá ưu đãi nhất","Giá mua vào","Số lượng","Giá tiền ưu đãi nhất",
        "toàn bộ","Xác nhận mua vào","Mức tiền"]
  },
  user: {
    default: [
      "Trung tâm cá nhân",
      "Tài khoản đăng nhập",
      "ID giới thiệu",
      "Đăng xuất",
      "Số dư",
      "Số tiền tệ",
      "Ví tiền",
    ],
    menu: [
      "Tài sản của tôi",
      "Danh sách nhóm",
      "Liên kết tài khoản ngân hàng",
      "Chi tiết tài khoản",
      "Giới thiệu nền tảng",
      "Đăng xuất",
    ],
  },
 bindAccount: {
    default: ["Liên kết tài khoản ngân hàng","Xác nhận"],
    fields: ["Họ và tên", "Số điện thoại", "Số tài khoản","Tên ngân hàng","loại","địa chỉ USDTl"],
    fields2: ["Họ và tên", "Số điện thoại", "Số tài khoản","Ngân hàng","loại","địa chỉ USDTl"],
    placeholder: ["Vui lòng nhập họ và tên","Vui lòng nhập số điện thoại","Vui lòng nhập số tài khoản",
        "Tên ngân hàng",
      "Vui lòng chọn loại USDT","vui lòng nhập địa chỉ USDT"],
     placeholder2: ["Vui lòng nhập họ và tên","Vui lòng nhập số điện thoại","Vui lòng nhập số tài khoản",
         "Vui lòng nhập tên ngân hàng",
         "Vui lòng chọn loại USDT","vui lòng nhập địa chỉ USDT"],
  },
  wallet: {
    default: [
      "Tài sản của tôi",
      "Nạp tiền",
      "Rút tiền",
      "Nhật ký nạp tiền",
      "Nhật ký rút tiền",
      "Số tiền đã nạp",
      "Số tiền đã bán ra",
      "Lợi nhuận đã nhận được",
      "Số dư tài khoản",
      "Số dư",
      "Số tiền nạp (USDT)",
      "Số tiền bán ra",
        "lợi nhuận",
        "nâng cấp",
      "Nâng cấp thương gia，vui lòng liên hệ chăm sóc khách hàng",
        "Xác nhận thương gia gia nhập",
        "Xác thực thương gia",
      "bạn cần thanh toán","chứng từ thanh toán","phí bảo đảm của thương gia","xác thực ngay","thông tin","bạn cần thanh toán","số dư không đủ, đã nạp tiền hay chưa"
    ],
    label: [
      "cách thức rút tiền",
      "Số tiền rút",
      "mật khẩu quỹ tiền",
      "giao nộp",
      "số điện thoại",
      "email",
      "IFSC",
      "khẳng định",
    ],
    placeholder: [
      "Lựa chọn cách thức rút tiền",
      "Vui lòng nhập số tiền rút",
      "Vui lòng nhập mật khẩu quỹ",
      "Vui lòng lựa chọn cách thức rút tiền",
      "Vui lòng nhập số điện thoại của người nhận tiền",
      "Vui lòng nhập email của người nhận tiền",
      "vui lòng nhập IFSC của người nhận tiền",
    ],
    msg: [
      "Bạn vẫn chưa cài đặt mật khẩu quỹ, vui lòng cài đặt trước",
      "Bạn chưa ràng buộc tài khoản PIX，vui lòng ràng buộc trước",
      "Xác nhận muốn rút tiền?",
    ],
  },
  recharge: {
    default: [
      "Nạp tiền vào ví",
      "Nạp tiền trước vào ví",
      "phương thức nạp tiền",
        "Nạp tiền ",
        "Xác nhận",
    ],
    label: [
      "Thể loại",
      "Địa chỉ",
      "Số tiền nạp",
      "Nhập số tiền",
      "Tải chứng từ lên",
        "sao chép thành công",
        "nạp tiền trước đến sàn giao dịch"
    ],
    placeholder: [
      "thể loại",
      "địa chỉ",
    ],
    info: [
      "Số tiền nạp",
      "Số đơn hàng",
      "Ngân hàng nhận tiền",
      "Tài khoản nhận tiền",
      "Người nhận tiền",
      "Sao chép",
    ]
  },
  task: {
    tabs: ["Toàn bộ","Đang xử lý", "Đã thất bại", "Đơn hàng bán ra","Đợi thương gia xác nhận","Đợi xác nhận"],
    default: ["Đã thất bại",],
    msg: ["Thành công", "đã giao nộp，vui lòng đợi xét duyệt", "giao nộp thất bại，vui lòng giao nộp lại"],
  },
  userInfo: {
    default: [
      "Thông tin cá nhân",
      "Ảnh đại diện",
      "Tài khoản",
      "Email",
      "Credit",
      "Thông tin chi tiết",
      "Sửa đổi mật khẩu",
      "Mật khẩu quỹ",
      "Ấn để cài đặt",
      "Sửa đổi ảnh đại diện",
      "Sửa đổi mật khẩu đăng nhập",
      "Sửa đổi mật khẩu quỹ",
      "Giao nộp",
      "xóa bộ nhớ dữ liệu",
      "Kiểm tra thông tin",
      "Nick name",
      "Mã giới thiệu"
    ],
    label: [
      "Mật khẩu đăng nhập cũ",
      "Mật khẩu đăng nhập mới",
      "Xác nhận mật khẩu",
      "Mật khẩu quỹ cũ",
      "Mật khẩu quỹ mới",
      "Xác nhận mật khẩu",
      "Lưu"
    ],
    placeholder: [
      "Vui lòng nhập mật khẩu đăng nhập cũ",
      "Vui lòng nhập mật khẩu đăng nhập mới",
      "Vui lòng xác nhận mật khẩu đăng nhập",
      "Vui lòng nhập mật khẩu quỹ cũ",
      "Vui lòng nhập mật khẩu quỹ mới",
      "Vui lòng xác nhận mật khẩu quỹ",
    ],
  },
  fundRecord: {
    default: ["lịch sử chi ra", "lịch sử nạp tiền", "Nhật ký tiền vốn", "nạp", "nhận", "chi"],
    tabs: ["nhận vào", "chi ra", "nạp tiền"],
  },
 dialog: [
    "Nhắc nhở",
    "khẳng định",
    "đang giao nộp...",
    "Sao chép thành công",
    "Phiên bản hệ thống iOS không được hỗ trợ",
    "Đang đăng ký...",
    "số liệu đang tải...",
  ],
  serviceCenter: [
    "trung tâm chăm sóc khách hàng",
    "Xin chào, tôi là chuyên viên chăm sóc khách hàng~",
    "rất vui được phục vụ bạn",
    "tự phục vụ",
    "chăm sóc khách hàng online",
    "chăm sóc khách hàng nạp tiền",
    "Line chăm sóc khách hàng",
  ],
 userTaskRecord: [
    "Đơn hàng của tôi",
    "Đơn hàng bán ra",
    "Đơn hàng mua vào",
    "Trạng thái trước đó",
    "hoa hồng nhận được ",
    "đã hoàn thành",
  ],
  withdrawlist: [
    "Lịch sử rút tiền",
  ],
  teamReport: {
    default: [
      "Danh sách nhóm",
    ],
  },
  common2:['Hướng dẫn người mới',"Liên hệ chăm sóc khách hàng","điều khoản dịch vụ，xem chi tiết","Chăm sóc khách hàng 1","chăm sóc khách hàng 2"],
  common3:['Thành công',"Giới thiệu nền tảng"],
  invite:['Chia sẻ quảng bá ','ID giới thiệu của tôi',"Sao chép ID giới thiệu,giới thiệu nhiều bạn bè tham gia","Sao chép",'Không thể mời thành viên vào lúc này'],
  common4:['Xác minh danh tính',"Giao nộp thành công","Hạng mục chú ý","Nội dung","Số tiền nạp","Mặt trước chứng minh thư",
      "Chứng minh tài vụ","Vui lòng hoàn thành liên kết thẻ ngân hàng","Vui lòng thành xác minh danh tính"],
  common5:["bán ra","phút","trước","lưu mã qr","mở",
      "đóng băng tài khoản, tạm thời không thể giao dịch","Tải APP"],
  common6:["Lịch sử nạp tiền","Số đơn hàng","Số tiền","Thời gian","Trạng thái"],
  hall2:["Số tiền bán ra tối thiểu là","","Số lần bán ra vượt quá giới hạn mỗi ngày"],
  register2:["Email","Số điện thoại","Số điện thoại cá nhân"],
  withdraw2:["Rút tiền","tài khoản người bán","Số tiền tài khoản","Số tiền cần thanh toán","thanh toán ngay","vẫn còn nhiệm vụ chưa hoàn thành, tạm thời không thể rút tiền","Số tiền rút hàng ngày đã vượt quá","chú thích giải thích rõ"],
  mytask2:["tài khoản thương gia","giá tiền","Số tiền","số dư tài khoản","Số tiền cần thanh toán","Thời gian ghép đôi","Chi tiết đơn hàng","số lượng không thể là 0","số dư không đủ","giá tiền không thể là 0","phạm vi sai"],
  taskOrder2:["Bạn vẫn có đơn hàng chưa thực hiện, không thể hủy bỏ","số dư không đủ","Xác nhận","thất bại","bạn cần phải hoàn thành đơn hàng mới có thể rút tiền:"],
    busAuth2:['Nâng cấp thương gia','tôi đồng ý',"Xác nhận nâng cấp","thương gia nâng cấp",
        "Nếu cần thay đổi thông tin liên kết vui lòng liên hệ bộ phận hỗ trợ","Tài khoản trước mắt cần được nâng cấp lên tài khoản thương gia"],
    recharge2:["Số tiền không được là 0","phải tải ảnh chụp màn hình lên"],
    buy3:["Đếm ngược","giờ","Loại",'Đơn hàng phúc lợi','Bán cho thương gia','Số người'
        ,'hạn Số người','Số người','bổ nhiệm người dùng ID'],
    hall3:["ngày","giờ","phút","giây","Buôn bán chung đơn hàng","tài khoản",'làm thêm'],
    sell3:['Giá bán không thể lớn hơn giá tối ưu.'],
    busAuth3:["Thành viên","Đơn thương gia","Vương bán","Đơn phúc lợi"],
    recharge3:["Tính"],
    home3: ["Nhật ký tiền vốn", "Câu đố", "Hộp mù", "Quản lý tài chính", "Kính mong đợi chờ"],
    home4: ["Giao dịch nhanh", "Nhấp vào mua USDT", "Giao dịch P2P", "Mua và bán USDT", "Người dùng trực tuyến", "Tổng khối lượng giao dịch 24H (USDT)"],
    common7: ["Nếu vấn đề của bạn chưa được giải quyết, vui lòng liên hệ trực tiếp với chăm sóc khách hàng",
        "Liên hệ bộ phận hỗ trợ", "Nhấp vào tư vấn", "Cài đặt", "Tất cả",
        "Mời bạn bè", "Quy mô nhóm", "Mới thêm trong tháng ","Thời gian đăng ký "," Số lượng cấp dưới "],
    hall4: ['Danh tính của bạn', 'Thành viên', "Thông tin tài khoản ",
        "Giao dịch thành công",
        "Bạn đã hoàn thành thành công", "Số tiền","Muốn mua","Bán"],
    task3:["Tất cả","Chờ xác nhận","Đã hoàn thành"],
    my:["Bạn có chắc muốn thoát khỏi tài khoản?"],
    bindAccount2:["Kiểm tra lại thông tin cho chính xác,nếu không đúng giao dịch sẽ bị ảnh hưởng","Xác nhận","Bộ phận hỗ trợ"],
    recharge4:["Để đảm bảo an toàn cho tài khoản của bạn,sau khi chuyển thành công.Vui lòng tải hình ảnh rút tiền thành công lên"],
    resetpwd2:['Email','Số điện thoại','Ghi chú: mật khẩu được tạo ra bởi 6~16 và không phải trường hợp nhạy cảm.'],
    hall5:["Ví tiền"],
    user3:["Đông cứng"],
    home2:["Thị trường tiền tệ"],
    login1:["Tiếp theo","Đăng nhập ngay","Có vẻ như bạn chưa có tài khoản. Hãy tạo một tài khoản mới:","Đồng ý và tiếp tục"],
    login2:["Cách đăng nhập khác","Tiếp tục với ","Facebook","Google","Apple"],
    service2:["Giao dịch","Khác"],
    buy2:["Loại lệnh","Mua thành công","USDT đã được mua thành công"],
    sell4:["Bạn xác nhận bán hàng này","Xác nhận","Bán hàng thành công","Bạn đã bán thành công","Chờ người mua thanh toán","Hoàn thành"],
    busauth:["Thông tin xác thực","Vui lòng nhập tên thật của bạn","Vui lòng nhập số ID của bạn",
        "tải ảnh lên","vui lòng tải ảnh ID của bạn lên","Vui lòng tải lên chi tiết ngân hàng"],
    recharge5:["Vui lòng tải ảnh lên","Nạp tiền thành công", "USDT đã được nạp thành công vào tài khoản","Xác nhận"],
    note:["Ghi chú","Gợi ý","Số điện thoại","Vui lòng nhập số điện thoại của bạn","Để lại tin nhắn","Vui lòng để lại tin nhắn của bạn ở đây", "Tải ảnh lên","Vui lòng tải ảnh lên","Gửi thành công","Chúng tôi sẽ liên hệ với bạn sớm nhất có thể","Đóng"],
    notelist:[ "Danh sách ghi chú" ],
    search:["Tìm kiếm","không tìm thấy dữ liệu","Vui lòng nhập từ khóa để tìm"],
    searchs:{ orders:"Đơn hàng",recharge:"Nạp tiền",withdraw:"Rút tiền" },
    team2:["Chi tiết đội ngũ","Mời bạn bè"],
    home5:["Tôi muốn mua"],
    task4:["My Task"],
    lang3:["English","Korean","Taiwanese"],
    login3:["Lỗi tài khoản hoặc mật khẩu, vui lòng nhập lại"],
    shiming:['Kiểm toán thuế'],
};
